.stih {
  white-space: pre-wrap;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 20vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  scroll-margin-bottom: 40vh;
  --webkit-overflow-scrolling: touch;
  font-family: NotoSerif, serif;
  will-change: opacity;
}
