.App {
  height: 100vh;
  overflow: scroll;
  --webkit-overflow-scrolling: touch;
  scroll-snap-type: y mandatory;
  padding-bottom: 100px;
}

.page {
  border-bottom: 10px solid #fafafa;
  will-change: opacity;
}

.List
{
  padding-bottom: 100px;
}

.Single {
  --webkit-overflow-scrolling: touch;
  overflow: scroll;
}

.backButton {
  position: absolute;
  left: 5px;
  top: 20px;
  width: 25px;
  opacity: 40%;
  z-index: 1;
}

.backButton:hover {
  cursor: pointer;
}

.center {
  text-align: center;
}

.message {
  margin-top: 100px;
  font-family: NotoSerif, serif;
}

.error-page {
  margin-top: 100px;
  font-family: NotoSerif, serif;
}

.install-page {
  margin-top: 50px;
  font-family: NotoSerif, serif;
}

.installButton {
  background-color: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 15px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  min-height: 35px;
  transition: all 0.2s ease;
}

.installedButton {
  background-color: #07b015;
  border: 1px solid #07b015;
  color: #ffffff;
  border-radius: 15px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  min-height: 35px;
  transition: all 0.2s ease;
}

.installButton:hover {
  background-color: #dbdbdb;
  text-decoration: none;
  border: 2px solid #dbdbdb;
  color: #000000;
}

.installButton:focus {
  outline: 0;
}

.animated {
  will-change: opacity;
}