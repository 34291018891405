.yearStihList {
    padding-left: 10px;
    will-change: opacity;
}
.yearAuthor {
    padding-top: 50px;
    margin-bottom: 25px;
}

.yearStihTitle a {
    font-family: Arsenal;
    color: #000;
    text-decoration-style: dotted;
    font-size: 1.3em;
}


.year {
    padding-top: 30px;
    text-align: center;
    font-family: Cormorant;
    font-size: 4em;
}

.yearAuthor a {
    text-decoration: none;
    color: #000;
}