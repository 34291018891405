/** Global preloader styles **/

.pl, .pl:before, .pl:after {
	animation-duration: 4s;
    animation-delay: 1000ms;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}
.pl {
	margin: 8em auto 1.5em auto;
	position: relative;
	width: 3em;
	height: 3em;
}
.pl:before, .pl:after {
    opacity: 0;
	background: #999999;
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 50%;
	transform-origin: 50% 100%;	
	clip-path: polygon(0 0,100% 0,50% 100%);
	-webkit-clip-path: polygon(0 0,100% 0,50% 100%);
}
/* Origami */
.pl-origami {
	animation-name: origamiA;
	animation-timing-function: steps(4);
}
.pl-origami:before, .pl-origami:after {
	clip-path: polygon(50% 0,100% 100%,0% 100%);
	-webkit-clip-path: polygon(50% 0,100% 100%,0% 100%);
}
.pl-origami:before {
	animation-name: origamiB;
}
.pl-origami:after {
	animation-name: origamiC;
	transform: rotate(180deg);
}
@keyframes origamiA {
	from { transform: rotate(0) }
	to { transform: rotate(-360deg) }
}
@keyframes origamiB {
	from, 25%, 50%, 75%, to { opacity: 1; transform: translateZ(0) rotateX(0deg) }
	12.5%, 62.5% { opacity: 1; transform: translateZ(1px) rotateX(-180deg) }
	37.5%, 87.5% { opacity: 0; transform: translateZ(0) rotateX(0deg) }
}
@keyframes origamiC {
	from, 25%, 50%, 75%, to { opacity: 1; transform: translateZ(0) rotateZ(180deg) rotateX(0deg) }
	12.5%, 62.5% { opacity: 0; transform: translateZ(0) rotateZ(180deg) rotateX(0deg) }
	37.5%, 87.5% { opacity: 1; transform: translateZ(1px) rotateZ(180deg) rotateX(-180deg) }
}