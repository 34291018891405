.button {
    background-color: #f6f6f6;
    border: 1px solid #c6c4c4;
    border-radius: 30px;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 32px;
    padding: 0 20px 0 20px;
    /* position: relative; */
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-height: 35px;
    transition: all 0.2s ease;
}

.button:hover {
    background-color: #f6f6f6;
    text-decoration: none;
    border: 1px solid #595959;
    color: #000000;
    opacity: 1;
}

.button:focus {
    outline: 0;
}