#menuToggle
{
  display: block;
  position: fixed;
  top: 15px;
  right: 0px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a
{
  font-family: NotoSerif,serif;
  text-decoration: none;
  color: #232323;
  transition: color 0.1s;
}

#menuToggle a:hover
{
  color: tomato;
}

#menuToggle input[type="checkbox"]
{
  display: block;
  width: 50px;
  height: 70px;
  position: absolute;
  top: -25px;
  right: 0px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#menuToggle span
{
  display: block;
  width: 18px;
  height: 2px;
  margin-bottom: 5px;
  margin-right: 10px;
  position: relative;
  background: #999999;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.1s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(1px, 1px) scaleX(1.31);
  background: #000000;
}

#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) scaleX(1.31);
}

#menu
{
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  display: block;
  user-select: none;
  -webkit-user-select: none;
  margin-top: -15px;
  position: absolute;
  height: 100vh;
  width: 300px;
  right: 0px;
  top: 0px;
  padding: 35px;
  padding-top: 85px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s;
}

#menu li
{
  padding: 10px 0;
  font-size: 22px;
  transition: all 0.1s ease;
  user-select: none;
  -webkit-user-select: none;
}

#menuToggle input:checked ~ ul
{
  transform: none;
}

.navHr {
  margin-top: 20vh;
  display: none;
}

.installLink {
  display: none;
}

@media (display-mode: browser) {
  .installLink {
    display: block
  }

  .navHr {
    display: block;
  }
}
