.backArea {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 50px;
    height: 50px;
    z-index: 1;
}

.backArea:hover {
    cursor: pointer;

}

.backButton {
    position: fixed;
    left: 5px;
    top: 15px;
    width: 25px;
    opacity: 40%;
    z-index: 1;
  }
  
.backButton:hover {
    cursor: pointer;
  }