.searchButton {
    background: url("../img/search.svg")  no-repeat center center;
    margin-left: -5px;
    width: 40px;
    border: 0px;
    transition: all 0.2s ease;
}

.searchButton:hover {
    opacity: 0.6;
}

.searchInputInactive {
    width: 300px;
    margin-left: -35px;
    font-family: NotoSerif serif;
    font-style: italic;
    opacity: 0;
    border: 0px;
    border-radius: 0;
    transition: all 0.3s ease;
    padding-left: 35px;
    height: 40px;
    margin-top:10px;
    -webkit-appearance: none;
    background: #fff;
    -webkit-tap-highlight-color: transparent;
    outline:0 !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.searchInputActive {
    border-radius: 0;
    padding-left: 35px;
    width: 300px;
    margin-left: -35px;
    opacity: 1;
    font-family: NotoSerif serif;
    font-style: italic;
    height: 40px;
    border: 0px;
    transition: all 0.3s ease;
    outline:0 !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    margin-top:10px;
    background: #fff;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}


.searchInput:focus,
.searchInput:active {
    outline:0 !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
