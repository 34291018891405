.filter {
    font-family: Arsenal;
    font-size: 16px;
    width: 100%;
    border: 1px solid #c6c4c4;
    border-radius: 15px;
    min-height: 35px;
    padding-left: 15px;
    margin-top: 20px;
    margin-bottom: 0px;
    color: #3f3f3f;
}


.filter:focus {
    outline: 0;
}

.filterDiv {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}