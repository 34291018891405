.authorDesc {
    padding-top: 30px;
    text-align: center;
    font-family: Cormorant;
    font-size: 2.3em;
    margin-bottom: 40px;
    will-change: opacity;
}

.authorDesc h1 {
    font-weight: normal;
    font-size: 1em;
}

.descText {
    font-size: 14px;
    color: #656565;
    font-family: NotoSerif, serif;
    margin-bottom: 40px;
}

.authorDesc h1 a {
    font-size: 1em;
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease;
    font-weight: normal;
}
.authorDesc h1 a:hover {
    color: #000;
    border-bottom: 1px solid #000;
}

.authorDesc h1 {
    margin-bottom: 10px;
}

.authorDescImg {
    width: 200px;
    border-radius: 50%;
    padding-bottom: 20px;
}