.title {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    padding-top: 20px;
    font-weight: bold;
    font-size: 1.5em;
    font-family: Arsenal;
}

.title h1 {
    font-weight: bold;
    font-size: 0.9em;
    font-family: Arsenal;
}

.title a {
    text-decoration: none;
    color: #000;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease;
}

.title a:hover {
    color:#000000;
    border-bottom: 1px solid #000000;
}