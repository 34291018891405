.date {
  color: #989898;
  padding-left: 10px;
  scroll-snap-stop: always;
}

.date a {
  text-decoration: none;
  color:#989898;
  font-style: italic;
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease;
}

.date a:hover {
  color: #989898;
  border-bottom: 1px solid #989898;
}
