.author {
  text-align: center;
  font-family: Cormorant;
}

.author h2 {
  text-align: center;
  font-size: 1.1em;
  font-family: Cormorant;
}

.author h2 a {
  text-decoration: none;
  color: #000;
  transition: all 0.2s ease;
  border-bottom: 1px solid transparent;
}

.author h2 a:hover {
  color: #000;
  border-bottom: 1px solid #000000;
}


.authorImg {
  border-radius: 50%;
  width: 50px;
  padding-bottom: 5px;
}
