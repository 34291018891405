.authorStihList {
    padding-top: 30px;
    padding-left: 10px;
    will-change: opacity;
}

.authorStihTitle {
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 20px;
}

.authorStihTitle h2 {
    font-size: 1em;
}

.authorStihTitle a {
    font-family: Arsenal;
    color: #000;
    text-decoration: none;
    border-bottom: 2px dotted #000;
    font-size: 1.3em;
    overflow: visible;
    transition: all 0.45s ease-out;
    white-space: initial;
}

.authorStihTitle a:hover {
    border-bottom: 2px solid #000;
}

.authorStihTitle p {
    display: inline
}